<template>
    <div class="row custom-debugx" style="display: flex; justify-content: center;">
        <draggable class="col list-section mx-2 custom-debugx" :class="{  'ranked-section': showUnRanked}" group="x" style="max-width: 800px;"
            v-model="items" handle=".handle" @start="isDragging=true" @change="handleListChange('ranked', $event)">
            <div v-for="(item, index) in items" :key="item.listItemId" class="">
                <ListItem
                :canEdit="canEdit"
                :id = "`list-item-${index+1}`"
                :index="index" 
                :isRanked="true"
                :item="item"
                :listFormat="listFormat"
                :disabled="!canEdit"
                :removeItem="removeItem"
                :refreshList="refreshList"
                />
            </div>
        </draggable>

        <draggable v-if="showUnRanked" class="col list-section unranked-section mx-2" group="x" style="max-width: 800px;" 
            v-model="unRankedItems" handle=".handle" @start="isDragging=true" @change="handleListChange('unranked', $event)">
            <div v-for="(item, index) in unRankedItems" :key="item.listItemId">
                <ListItem
                :canEdit="canEdit"
                :id = "`list-item-${index+1}`"
                :index="index" 
                :isRanked="false"
                :item="item"
                :listFormat="listFormat"
                :disabled="!canEdit"
                :removeItem="removeUnrankedItem"
                :refreshList="refreshList"
                />
            </div>
        </draggable>
    </div>
</template>
<script>
import auth from "/src/utils/auth";
import api from '/src/utils/api';
import draggable from 'vuedraggable';

export default {
    components: {
        draggable,
        ListItem: () => import("/src/views/list/components/ListItem.vue"),
    },
    data() {
        return {
            items: [],
            unRankedItems: [],
            canEdit: false,
        };
    },
    watch: {
        // Watching for changes in the 'watchedProperty' data property
        unRankedItems(newValue, oldValue) {
            if(newValue?.length > 0)
                this.showUnrankToggle();
        },
    },
    mounted() {
        // console.log("ListItems::items", this.items.count);
        console.log("ListItems::publicMode", this.publicMode);
        this.canEdit = auth.isViewingSelf() || this.publicMode;
        this.refreshList();
    },
    methods: {
        handleListChange(listName, event) {
            let movedItem = event.added || event.moved;
            let listItemId = movedItem?.element.listItemId;
            let ranking = movedItem?.newIndex + 1;
            if (movedItem) {
                if (listName=='ranked') {
                    // We stopped refreshing the list because the movement on the screen was too jarring.
                    api.postAuthOptional('update-list-item-ranking', {listItemId, ranking});
                }
                if (listName=='unranked') {
                    api.postAuthRequired('unrank-list-item', {listItemId}).then(() => {
                        // this.refreshList();
                    });
                }
            }
        },
        removeItem(index) {
            this.items.splice(index, 1);
        },
        removeUnrankedItem(index) {
            this.unRankedItems.splice(index, 1);
        },
        refreshList(scrollToItem) {
            this.items = [];
            api.getCall(`get-list-items?listId=${this.viewingListId}`).then(items => {
                this.items = items.filter(i => !!i.ranking);
                this.unRankedItems = items.filter(i => !i.ranking);
            })
            .then(() => {
                if (scrollToItem) {
                    setTimeout(() => { 
                        let newItemId = `list-item-${scrollToItem}`;
                        let newItem = document.getElementById(newItemId);
                        window.scrollTo({
                            top: newItem?.offsetTop,
                            behavior: 'smooth'
                        });
                    }, 100);
                }
            });
        },
    },
    props: ['viewingListId','listFormat', 'publicMode', 'showUnRanked', 'showUnrankToggle'],
};
</script>
<style scoped>
.list-section {
    min-width: 400px;
}
.ranked-section {
    background-color: #5ef55e;
    /* #cdeffa */
    /* min-height:100px; */
}
.ranked-section::before {
    color: white;
    content: "Ranked";
    font-size: 1.5em;
    font-weight: bold;
}
.unranked-section {
    background-color: #4f844f;
    /* min-height:100px; */
}
.unranked-section::before {
    color: white;
    content: "Unranked";
    font-size: 1.5em;
    font-weight: bold;
}

</style>